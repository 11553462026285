import styled from "styled-components"

import MuiSkeleton from "@material-ui/lab/Skeleton"

import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

const HEIGHT = 25

export function PaymentsLoading() {
  return (
    <Box>
      <Skeleton variant="rect" height={HEIGHT} />
      <Skeleton variant="rect" height={HEIGHT} />
      <Skeleton variant="rect" height={HEIGHT} />
      <Skeleton variant="rect" height={HEIGHT} />
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.XL};
  > * {
    margin-bottom: ${HEIGHT / 2}px;
  }
`

const Skeleton = styled(MuiSkeleton)`
  background: ${backgroundGrayV2};
`

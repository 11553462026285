import { useState } from "react"
import styled from "styled-components"

import { useMediaQuery } from "@material-ui/core"

import { formatUTCSecondsDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { IInvoice } from "src/components/Account/types"
import { EmptyState } from "src/components/EmptyState"
import {
  HREF_MINUT_SUBSCRIPTION_TERMS,
  HREF_MINUT_TERMS_OF_SERVICE,
} from "src/constants/hrefs"
import {
  useFetchInfinteInvoices,
  usePostFetchInvoicePdf,
} from "src/data/invoices/queries/InvoiceQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import InfoIcon from "src/ui/icons/important-filled.svg"
import InvoiceIcon from "src/ui/icons/invoice.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { DesktopTable } from "./DesktopTable"
import { MobileTable } from "./MobileTable"
import { PayInvoiceDialog } from "./PayInvoiceDialog"
import { PaymentsLoading } from "./PaymentsLoading"

export function Payments() {
  const { t, langKeys } = useTranslate()
  const fetchInvoices = useFetchInfinteInvoices({ limit: 10 })
  const [index, setIndex] = useState(0)
  const [payInvoiceId, setPayInvoiceId] = useState<string | null>(null)
  const desktop = useMediaQuery(`(min-width: 1000px)`)
  const postFetchInvoicePdf = usePostFetchInvoicePdf()

  async function getPDF(invoiceId: string) {
    try {
      const downloadUrl = await postFetchInvoicePdf.mutateAsync({
        invoiceId,
      })

      window.open(downloadUrl, "_blank")
    } catch (error) {
      // TODO: WEB-399  Handle gracefully
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  if (fetchInvoices.error) {
    return (
      <MBanner type="error">
        {t(langKeys.failed_to_load)}. {t(langKeys.failed_contact_support)}
      </MBanner>
    )
  }

  if (fetchInvoices.isLoading) {
    return <PaymentsLoading />
  }

  const invoices: IInvoice[] =
    fetchInvoices.data?.pages[index]?.list.map((i) => i.invoice) || []

  if (invoices.length === 0 && !fetchInvoices.isFetching) {
    return (
      <EmptyState
        icon={<InvoiceIcon />}
        title={t(langKeys.invoice_empty_state_title)}
        body={t(langKeys.invoice_empty_state_body)}
      />
    )
  }

  if (invoices.length === 0 && fetchInvoices.isFetching) {
    return <PaymentsLoading />
  }

  return (
    <>
      {desktop ? (
        <>
          <DesktopTable
            invoices={invoices}
            onPayInvoice={(id) => setPayInvoiceId(id)}
            getPDF={getPDF}
          />
        </>
      ) : (
        <MobileTable
          invoices={invoices}
          onPayInvoice={(id) => setPayInvoiceId(id)}
          getPDF={getPDF}
        />
      )}

      <PagerBox>
        <TextButton
          style={{ marginRight: spacing.S }}
          disabled={index === 0}
          onClick={() => {
            setIndex(Math.max(index - 1, 0))
          }}
        >
          {t(langKeys.back)}
        </TextButton>

        <TextButton
          disabled={
            index === fetchInvoices.data.pages.length - 1 &&
            !fetchInvoices.hasNextPage
          }
          onClick={() => {
            fetchInvoices.fetchNextPage()
            const pagesSize = fetchInvoices.data?.pages.length || 0
            const nextIndex = fetchInvoices.hasNextPage
              ? Math.min(index + 1, pagesSize)
              : Math.min(index + 1, pagesSize - 1)

            setIndex(nextIndex)
          }}
        >
          {t(langKeys.next)}
        </TextButton>
      </PagerBox>

      <Footer>
        <ExternalLink
          href={HREF_MINUT_TERMS_OF_SERVICE}
          color="tertiary"
          underline="onHover"
        >
          {t(langKeys.terms_of_service)}
        </ExternalLink>

        <ExternalLink
          href={HREF_MINUT_SUBSCRIPTION_TERMS}
          color="tertiary"
          underline="onHover"
        >
          {t(langKeys.subscription_terms)}
        </ExternalLink>
      </Footer>

      {payInvoiceId !== null && (
        <PayInvoiceDialog
          invoice={invoices.find((i) => i.id === payInvoiceId)}
          onSuccess={() => fetchInvoices.refetch()}
          open={true}
          onClose={() => setPayInvoiceId(null)}
        />
      )}
    </>
  )
}

export function NextRetry({
  invoice,
  style,
}: {
  invoice: IInvoice
  style?: React.CSSProperties
}) {
  const { t, langKeys } = useTranslate()

  if (!invoice.next_retry_at) {
    return null
  }

  return (
    <NextRetryBox style={style}>
      <MText variant="bodyS" color="secondary">
        <InfoIcon width="20px" style={{ marginRight: spacing.XS2 }} />{" "}
        {t(langKeys.invoice_next_retry)}:{" "}
        {formatUTCSecondsDate(invoice.next_retry_at)}
      </MText>
    </NextRetryBox>
  )
}

const NextRetryBox = styled.div`
  display: flex;
  align-items: center;
`

const PagerBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing.S};
`

const Footer = styled.div`
  display: flex;
  gap: ${spacing.M};
  margin-top: ${spacing.S};
`

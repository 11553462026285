import { IBillingAddress } from "src/components/Account/types"

export function transformBillingAddress(billingAddress: IBillingAddress) {
  if (!billingAddress.country) {
    throw Error("")
  }

  // Chargebee only allows to send state code when the country is United States, Canada or India.
  // So below we make sure that we send the right parameter.
  if (["CA", "US", "IN"].includes(billingAddress.country)) {
    return billingAddress
  } else {
    const { state_code, ...tempBilling } = billingAddress
    return { ...tempBilling, state: billingAddress.state_code }
  }
}

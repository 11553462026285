import { useEffect } from "react"
import styled from "styled-components"

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core"
import { differenceInDays, format } from "date-fns"
import { useFormik } from "formik"

import { formatUTCStringDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { ButtonRating } from "src/components/Account/BillingPortal/YourPlan/ButtonRating"
import { CustomPlanInfoBox } from "src/components/Account/BillingPortal/YourPlan/CustomPlanInfoBox"
import { breakpoint } from "src/constants/breakpoints"
import {
  TCancelReason,
  usePostTrackCancelCompleteEvent,
  usePostTrackCancelInitEvent,
} from "src/data/analytics/queries/planAnalyticsQueries"
import {
  useFetchAvailablePlansForUser,
  useFetchCurrentSubscription,
  usePostCancelSubscription,
} from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

type TCancelFormFields = {
  experienceRating: number | null
  cancelReason: TCancelReason | null
  additionalFeedback: string
  returnProbability: number | null
}

export function CancelPlanDialog({
  open,
  hasCSM,
}: {
  open: boolean
  hasCSM: boolean
}) {
  const { _t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()
  const user = useGetUser()

  const form = useFormik<TCancelFormFields>({
    initialValues: {
      experienceRating: null,
      cancelReason: null,
      additionalFeedback: "",
      returnProbability: null,
    },
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: () => {
      cancelPlan()
    },
  })

  const fetchCurrentSubscription = useFetchCurrentSubscription({
    userId: user.user_id,
  })
  const postCancelSubscription = usePostCancelSubscription()
  const postTrackCancelCompleteEvent = usePostTrackCancelCompleteEvent()
  const postTrackCancelInitEvent = usePostTrackCancelInitEvent()
  const fetchAvailablePlansForUser = useFetchAvailablePlansForUser({
    userId: user.user_id,
  })
  const subscription = fetchCurrentSubscription.data
  const currentPlan = fetchAvailablePlansForUser.data?.current_plan

  const today = new Date()

  // Send a plan cancel initiated event for tracking
  // next_billing_at is an optional prop but is required to calculate the remaining days.
  // The cancel plan initialised event is also required to have this remaining_days prop sent.
  useEffect(() => {
    if (open && subscription?.next_billing_at) {
      const remainingDays = differenceInDays(
        new Date(subscription?.next_billing_at),
        today
      )

      postTrackCancelInitEvent.mutate({ remaining_days: remainingDays })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, subscription?.next_billing_at])

  async function cancelPlan() {
    if (!subscription?.next_billing_at) {
      throw Error(
        "Undefined subscription or next billing date when trying to cancel plan"
      )
    }

    if (
      !form.values.cancelReason ||
      !form.values.experienceRating ||
      !form.values.returnProbability
    ) {
      // The submit button is already disabled  based on cancelReason existance
      // so technically this shouldn't happen
      throw Error(
        "Required fields are not set, but ideally this should not happen"
      )
    }

    const remainingDays = differenceInDays(
      new Date(subscription?.next_billing_at),
      today
    )

    postTrackCancelCompleteEvent.mutate({
      cancellation_date: format(new Date(), "yyyy-MM-dd"),
      cancel_reason: form.values.cancelReason,
      cancel_comment: form.values.additionalFeedback,
      experience_rating: form.values.experienceRating,
      return_probability: form.values.returnProbability,
      remaining_days: remainingDays,
    })

    try {
      await postCancelSubscription.mutateAsync({
        subscriptionId: subscription.subscription_id,
        subscriptionBody: {
          end_of_term: true,
        },
      })
      navigate(Routes.ChangePlanSuccess.location())
    } catch (error) {
      ErrorService.captureException(error)
    }
  }

  function handleOnClose() {
    form.resetForm()
    form.validateForm()
    goBack({ defaultPath: Routes.AccountBilling.location() })
  }

  if (currentPlan?.custom_plan || hasCSM) {
    return (
      <ConfirmDialog
        title={_t(langKeys.cancel_plan_title)}
        onClose={handleOnClose}
        showCancel={false}
        onConfirm={() => handleOnClose()}
        confirmLabel={_t(langKeys.close)}
        open={open}
      >
        <CustomPlanInfoBox />
      </ConfirmDialog>
    )
  }

  const cancelPlanReasons = getcancelPlanReasons(_t)

  const submitButtonDisabled =
    !form.values.experienceRating ||
    !form.values.cancelReason ||
    !form.values.returnProbability

  return (
    <MDialog
      title={_t(langKeys.cancel_plan_title)}
      onClose={handleOnClose}
      open={open}
      loading={
        postCancelSubscription.isLoading || fetchAvailablePlansForUser.isLoading
      }
      error={
        postCancelSubscription.isError &&
        _t(langKeys.failed_something_went_wrong)
      }
      confirmButtonProps={{
        disabled: submitButtonDisabled,
        color: "destructive",
      }}
      confirmLabel={_t(langKeys.cancel_plan)}
      formId={CANCEL_PLAN_FORM_ID}
      showCancel={false}
    >
      <Form id={CANCEL_PLAN_FORM_ID} onSubmit={form.handleSubmit}>
        <MText variant="body">
          {_t(langKeys.cancel_plan_body, {
            date: formatUTCStringDate(subscription?.next_billing_at),
          })}
        </MText>

        <MText variant="heading3">
          {_t(langKeys.cancel_plan_experience_rating_question)}
        </MText>
        <ButtonRatingWrapper>
          <ButtonRating
            min={1}
            max={5}
            value={form.values.experienceRating}
            labels={{
              start: _t(langKeys.cancel_plan_experience_rating_poor),
              end: _t(langKeys.cancel_plan_experience_rating_excellent),
            }}
            onClick={(v) => form.setFieldValue("experienceRating", v)}
          />
        </ButtonRatingWrapper>

        <MText variant="heading3">
          {_t(langKeys.cancel_plan_reason_title)}
        </MText>
        <MText variant="body">{_t(langKeys.cancel_plan_reason_body)}</MText>
        <FormControl error={postCancelSubscription.isError}>
          <RadioGroup
            name="cancelReason"
            value={form.values.cancelReason}
            onChange={form.handleChange}
          >
            {cancelPlanReasons.map((reason) => (
              <FormControlLabel
                key={reason.reasonId}
                value={reason.reasonId}
                control={<Radio />}
                label={_t(reason.label)}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <MText variant="heading3">
          {_t(langKeys.cancel_plan_what_could_we_have_done_differently)}{" "}
          <MText as="span" variant="bodyS">
            ({_t(langKeys.input_optional)})
          </MText>
        </MText>
        <TextField
          name="additionalFeedback"
          value={form.values.additionalFeedback}
          onChange={form.handleChange}
          multiline
          minRows="4"
          maxRows="4"
          placeholder={_t(langKeys.cancel_plan_additional_placeholder)}
        />

        <MText variant="heading3">
          {_t(langKeys.cancel_plan_come_back_question)}
        </MText>
        <ButtonRatingWrapper>
          <ButtonRating
            min={1}
            max={5}
            value={form.values.returnProbability}
            labels={{
              start: _t(langKeys.cancel_plan_return_probability_very_unlikely),
              end: _t(langKeys.cancel_plan_return_probability_very_likely),
            }}
            onClick={(v) => form.setFieldValue("returnProbability", v)}
          />
        </ButtonRatingWrapper>
      </Form>
    </MDialog>
  )
}

const CANCEL_PLAN_FORM_ID = "cancel-plan-form"

// Order of array matters, since it determines rendering order
function getcancelPlanReasons(t: TTranslateFunction): {
  reasonId: TCancelReason
  label: string
}[] {
  return [
    {
      reasonId: "tech_problems",
      label: t(langKeys.cancel_plan_reason_tech_problems),
    },
    {
      reasonId: "not_hosting",
      label: t(langKeys.cancel_plan_reason_not_hosting),
    },
    {
      reasonId: "switching_solution",
      label: t(langKeys.cancel_reason_switching_to_another_solution),
    },
    {
      reasonId: "missing_features",
      label: t(langKeys.cancel_reason_missing_feature),
    },
    {
      reasonId: "not_meet_expectations",
      label: t(langKeys.cancel_plan_reason_not_meet_expectations),
    },
    {
      reasonId: "too_expensive",
      label: t(langKeys.cancel_reason_too_expensive),
    },
    {
      reasonId: "other",
      label: `${t(langKeys.cancel_plan_reason_other)} (${t(langKeys.cancel_plan_please_specify_below)})`,
    },
  ]
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
`

const ButtonRatingWrapper = styled.div`
  padding: 0;

  @media (${breakpoint.mediumUp}) {
    padding-inline: ${spacing.XL3};
  }
`

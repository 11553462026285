import { useState } from "react"
import styled from "styled-components"

import {
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core"

import {
  countryData,
  getProvinceArray,
  isEuropeanUnionMemberState,
} from "src/components/Account/BillingPortal/countryData"
import { IBillingAddress } from "src/components/Account/types"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { spacing } from "src/ui/spacing"

export function BillingFields({
  billingAddress,
  vatNumber,
  setField,
  setVatNumber,
}: {
  vatNumber: string | undefined
  billingAddress: IBillingAddress | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  setField: (param: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  setVatNumber: (param: any) => void
}) {
  const [showVatField, setShowVatField] = useState(
    vatNumber && vatNumber.length > 0
  )
  const provinces = getProvinceArray(billingAddress?.country)

  const { t } = useTranslate()

  function printOptional() {
    return `(${t(langKeys.input_optional)?.toLocaleLowerCase()})`
  }

  return (
    <Grid>
      <Row>
        <TextField
          label={t(langKeys.first_name)}
          fullWidth
          required
          autoComplete="given-name"
          value={valueWrapper(billingAddress?.first_name)}
          onChange={(e) => setField({ first_name: e.target.value })}
        />
        <TextField
          label={t(langKeys.last_name)}
          autoComplete="family-name"
          fullWidth
          required
          value={valueWrapper(billingAddress?.last_name)}
          onChange={(e) => setField({ last_name: e.target.value })}
        />
      </Row>

      <TextField
        label={`${t(langKeys.address_company)} ${printOptional()}`}
        autoComplete="organization"
        fullWidth
        value={valueWrapper(billingAddress?.company)}
        onChange={(e) => setField({ company: e.target.value })}
      />

      <TextField
        label={t(langKeys.email)}
        type="email"
        autoComplete="email"
        fullWidth
        required
        value={valueWrapper(billingAddress?.email)}
        onChange={(e) => setField({ email: e.target.value })}
      />

      <TextField
        label={`${t(langKeys.phone)} ${printOptional()}`}
        type="phone"
        fullWidth
        autoComplete="tel"
        value={valueWrapper(billingAddress?.phone)}
        onChange={(e) => setField({ phone: e.target.value })}
      />

      <TextField
        label={t(langKeys.address_form_street_name_1)}
        autoComplete="address-line-1"
        fullWidth
        required
        value={valueWrapper(billingAddress?.line1)}
        onChange={(e) => setField({ line1: e.target.value })}
      />

      <TextField
        label={`${t(langKeys.address_form_street_name_2)} ${printOptional()}`}
        autoComplete="address-line-2"
        fullWidth
        value={valueWrapper(billingAddress?.line2)}
        onChange={(e) => setField({ line2: e.target.value })}
      />

      <Row>
        <TextField
          label={`${t(langKeys.address_form_city)}`}
          autoComplete="address-level2"
          fullWidth
          required
          value={valueWrapper(billingAddress?.city)}
          onChange={(e) => setField({ city: e.target.value })}
        />
        <TextField
          label={t(langKeys.address_form_postcode)}
          autoComplete="postal-code"
          fullWidth
          required
          value={valueWrapper(billingAddress?.zip)}
          onChange={(e) => setField({ zip: e.target.value })}
        />
      </Row>

      <Row>
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            htmlFor="country"
            required
            style={{ background: "white" }}
          >
            {t(langKeys.address_form_country)}
          </InputLabel>
          <Select
            autoComplete="country"
            native
            value={valueWrapper(billingAddress?.country)}
            required
            inputProps={{
              id: "country",
            }}
            onChange={(e) => {
              setField({ country: e.target.value as string })
            }}
          >
            <option value="" disabled hidden />
            {countryData.map((country) => (
              <option value={country.code} key={country.code}>
                {country.label}
              </option>
            ))}
          </Select>
        </FormControl>

        {provinces.length > 0 && (
          <>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="address-level1"
                style={{ background: "white" }}
                required
              >
                {t(langKeys.address_form_state)}
              </InputLabel>
              <Select
                autoComplete="address-level1"
                native
                value={
                  valueWrapper(billingAddress?.state_code) ||
                  valueWrapper(billingAddress?.state)
                }
                required
                inputProps={{
                  id: "address-level1",
                }}
                onChange={(e) => {
                  setField({
                    state_code: e.target.value as string,
                  })
                }}
              >
                <option value="" disabled hidden></option>
                {provinces.map((province) => (
                  <option value={province.code} key={province.code}>
                    {province.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Row>

      {isVatExempt(billingAddress?.country) &&
        (showVatField ? (
          <Row>
            <TextField
              autoComplete="organization-vat"
              fullWidth
              placeholder=""
              value={valueWrapper(vatNumber)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <b>{valueWrapper(billingAddress?.country)}</b>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setVatNumber(e.target.value)}
            />
          </Row>
        ) : (
          <AddButton
            onClick={() => setShowVatField(true)}
            style={{ width: "fit-content" }}
          >
            {t(langKeys.billing_add_vat_number)}
          </AddButton>
        ))}
    </Grid>
  )
}

function valueWrapper(value?: string) {
  if (!value) {
    return ""
  }

  return value
}

function isVatExempt(countryCode: string | undefined): boolean {
  const _countryCode = countryCode?.toUpperCase()
  return isEuropeanUnionMemberState(_countryCode) && _countryCode !== "SE"
}

const Grid = styled.div`
  display: grid;
  row-gap: 1rem;
`

const Row = styled.div`
  display: flex;

  > * {
    margin-left: ${spacing.XS2};
    margin-right: ${spacing.XS2};
  }

  *:first-child {
    margin-left: 0;
  }

  *:last-child {
    margin-right: 0;
  }
`

import styled from "styled-components"

import { BillingAddress } from "src/components/Account/BillingPortal/BillingAddress/BillingAddress"
import { PaymentMethod } from "src/components/Account/BillingPortal/PaymentMethod/PaymentMethod"
import { YourPlanLoading } from "src/components/Account/BillingPortal/YourPlan/YourPlanLoading"
import { useFetchOrCreateCustomer } from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { divider } from "src/ui/colors"
import { PageHelmet } from "src/ui/Helmet/PageHelmet"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { Payments } from "./Payments/Payments"
import { YourPlan } from "./YourPlan/YourPlan"

export function BillingPortal() {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const fetchOrCreateCustomer = useFetchOrCreateCustomer({ user })

  if (fetchOrCreateCustomer.isError) {
    return <MBanner type="error">{t(langKeys.failed_contact_support)}</MBanner>
  }

  const customer = fetchOrCreateCustomer.data
  const isValidLocation = customer?.is_location_valid ?? true

  if (fetchOrCreateCustomer.isLoading || !customer) {
    return <YourPlanLoading />
  }

  return (
    <>
      <PageHelmet helmet={t(langKeys.settings_billing)} />

      <FlexBox>
        <PlanBox>
          <YourPlan
            balances={customer.balances}
            taxability={customer.taxability}
            hasCSM={!!customer.cf_csm}
          />
        </PlanBox>

        <BillingBox>
          <div>
            {!isValidLocation && (
              <StyledMAlert type="error">
                {t(langKeys.billing_location_verification_error)}
              </StyledMAlert>
            )}

            <MText variant="heading3" marginBottom={spacing.S}>
              {t(langKeys.payment_method)}
            </MText>

            <PaymentMethod customer={customer} userId={user.user_id} />
          </div>

          <div>
            <MText variant="heading3">{t(langKeys.billing_address)}</MText>

            <MText variant="bodyS" color="secondary" marginBottom={spacing.M}>
              {t(langKeys.billing_address_payment_method_match)}
            </MText>

            <BillingAddress />
          </div>
        </BillingBox>
      </FlexBox>

      <InvoiceBox>
        <MText variant="heading2" marginBottom={spacing.M}>
          {t(langKeys.payments)}
        </MText>
        <Payments />
      </InvoiceBox>
    </>
  )
}

const minWidth = "1200px"

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${minWidth}) {
    flex-direction: row;
  }
`

const PlanBox = styled.div`
  flex: 1;
  margin-bottom: ${spacing.XL};
  @media (min-width: ${minWidth}) {
    padding-right: ${spacing.XL2};
    border-right: 1px solid ${divider};
  }
`

const BillingBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  height: 100%;

  @media (min-width: ${minWidth}) {
    max-width: 450px;
    padding-left: ${spacing.XL};
  }
`

const InvoiceBox = styled.div`
  margin-top: ${spacing.XL};
  padding-top: ${spacing.XL};
  border-top: 1px solid ${divider};
`

const StyledMAlert = styled(MBanner)`
  margin-bottom: ${spacing.S};
`

import styled from "styled-components"

import MuiSkeleton from "@material-ui/lab/Skeleton"

import { backgroundGrayV2 } from "src/ui/colors"

export function PaymentMethodLoading() {
  return (
    <Skeleton variant="rect" height={130} style={{ borderRadius: "14px" }} />
  )
}

const Skeleton = styled(MuiSkeleton)`
  background: ${backgroundGrayV2};
`

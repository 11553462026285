import { useState } from "react"

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core"

import { IBillingAddress } from "src/components/Account/types"
import { usePostUpdateBillingInfo } from "src/data/billing/queries/billingQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

import { BillingFields } from "./BillingFields"
import { transformBillingAddress } from "./saveAddress"
import { useBillingFields } from "./useBillingFields"

export function EditBillingDialog({
  vatNumber,
  billingAddress,
  customerId,
  open = false,
  onClose,
}: {
  open: boolean
  customerId: string
  onClose: () => void
  vatNumber?: string
  billingAddress?: IBillingAddress
}) {
  const { t } = useTranslate()
  const [saving, setSaving] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const [error, setError] = useState<any>(null)
  const {
    billingAddress: _billingAddress,
    vatNumber: _vatNumber,
    setField,
    setVatNumber,
  } = useBillingFields(billingAddress, vatNumber)
  const postUpdateBillingInfo = usePostUpdateBillingInfo()

  const fullScreen = useMediaQuery(`(max-width: 800px)`)
  const dialogId = "edit-billing-address-dialog"
  const formId = "edit-billing-address-form"

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  async function handleSubmit(e: any) {
    e.preventDefault()

    setError(null)
    setSaving(true)

    try {
      if (!customerId) {
        throw Error(
          "No customer id present when trying to save billing address"
        )
      }

      await postUpdateBillingInfo.mutateAsync({
        customerId,
        body: {
          vat_number: _vatNumber,
          billing_address: transformBillingAddress(_billingAddress),
        },
      })

      onClose()
    } catch (err) {
      setError(err)
    }

    setSaving(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>{t(langKeys.billing_address)}</DialogTitle>

      {/* when scroll is set to body, overflow must be unset to avoid a double scroll */}
      <DialogContent style={{ overflowY: "unset" }}>
        <form id={formId} onSubmit={handleSubmit}>
          <BillingFields
            billingAddress={_billingAddress}
            vatNumber={_vatNumber}
            setField={setField}
            setVatNumber={setVatNumber}
          />
        </form>

        {error && (
          <MBanner type="error" style={{ margin: `${spacing.S} 0` }} fullWidth>
            {(error?.response?.status === 400 &&
              error?.response?.data.error_msg) ||
              `${t(langKeys.failed_to_save)}. ${t(
                langKeys.failed_contact_support
              )}`}
          </MBanner>
        )}
      </DialogContent>

      <DialogActions>
        <MButtonLegacy
          onClick={onClose}
          variant="text"
          disabled={saving}
          size="large"
        >
          {t(langKeys.cancel)}
        </MButtonLegacy>
        <MButtonLegacy
          type="submit"
          form={formId}
          loading={saving}
          size="large"
        >
          {t(langKeys.save)}
        </MButtonLegacy>
      </DialogActions>
    </Dialog>
  )
}

import styled from "styled-components"

import { colors, divider } from "src/ui/colors"
import { SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface IPlanData {
  key: string
  skip?: boolean
  title: React.ReactNode
  value?: React.ReactNode
}

export function PlanDataTable({
  planData,
  hidden,
}: {
  planData: IPlanData[]
  hidden?: boolean
}) {
  if (hidden) {
    return null
  }
  const filteredDetails = planData.filter((detail) => !detail.skip)

  return <DataTable planData={filteredDetails} />
}

function DataTable({ planData }: { planData: IPlanData[] }) {
  return (
    <Grid>
      {planData.map((detail) => {
        return (
          <GridRow key={detail.key}>
            {detail.title && <Cell1>{detail.title}</Cell1>}
            <Cell2>{detail.value}</Cell2>
          </GridRow>
        )
      })}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.backgroundGrayV3};
  overflow: auto;

  & > :not(:last-child) {
    border-bottom: 1px solid ${divider};
  }
`

const GridRow = styled.div`
  display: flex;
  gap: ${spacing.M};
  flex-wrap: wrap;
  padding: ${spacing.M};
`

const Cell1 = styled.div`
  ${SubtitleMixin};
  flex: 1 0 50%;
`

const Cell2 = styled.div`
  flex: 1 1 auto;
  text-align: end;
`

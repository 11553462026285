import styled from "styled-components"

import MuiSkeleton from "@material-ui/lab/Skeleton"

import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export function YourPlanLoading() {
  return (
    <>
      <Skeleton
        variant="rect"
        height={10}
        width={100}
        style={{ marginBottom: spacing.S }}
      />
      <Skeleton
        variant="rect"
        height={20}
        width={200}
        style={{ marginBottom: spacing.S }}
      />
      <Skeleton
        variant="rect"
        height={40}
        style={{ marginBottom: spacing.XL2 }}
      />
      <Skeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <Skeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <Skeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <Skeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
    </>
  )
}

const Skeleton = styled(MuiSkeleton)`
  background: ${backgroundGrayV2};
`

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  invoiceKeys,
  useInvoiceCache,
} from "src/data/invoices/queries/invoiceQueryCache"
import {
  ICollectPaymentResponse,
  InvoicesData,
} from "src/data/invoices/types/InvoiceTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchInfinteInvoices({
  limit,
  options,
}: {
  limit: number
  options?: UseInfiniteQueryOptions<
    InvoicesData,
    AxiosError,
    InvoicesData,
    InvoicesData,
    ReturnType<typeof invoiceKeys.all>
  >
}) {
  async function fetchInvoices({ pageParam = null }): Promise<InvoicesData> {
    const response = await minutApiHttpClient.get<InvoicesData>(
      `${API_DEFAULT}/chargebee/invoices`,
      {
        params: {
          limit,
          offset: pageParam,
        },
      }
    )
    const invoicesData = response.data
    return invoicesData
  }

  return useInfiniteQuery(invoiceKeys.all(), fetchInvoices, {
    ...options,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next_offset
    },
  })
}

export function useFetchNonPaidInvoices(props?: {
  options?: UseQueryOptions<
    InvoicesData,
    AxiosError,
    InvoicesData,
    ReturnType<typeof invoiceKeys.nonPaid>
  >
}) {
  async function fetchInvoices(): Promise<InvoicesData> {
    const response = await minutApiHttpClient.get<InvoicesData>(
      `${API_DEFAULT}/chargebee/invoices`,
      {
        params: {
          limit: 10,
          status: "not_paid",
        },
      }
    )
    const invoicesData = response.data
    return invoicesData
  }

  return useQuery(invoiceKeys.nonPaid(), () => fetchInvoices(), props?.options)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostCollectPayment<ErrorType = any>() {
  const cache = useInvoiceCache()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  async function postCollectPayment(invoiceId: string): Promise<any> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/invoices/${invoiceId}/collect_payment`
    )
    const result: ICollectPaymentResponse = response.data
    return result
  }

  return useMutation<
    ICollectPaymentResponse,
    AxiosError<ErrorType>,
    {
      invoiceId: string
    }
  >(({ invoiceId }) => postCollectPayment(invoiceId), {
    onSuccess: (data) => {
      cache.resetInvoices()
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostFetchInvoicePdf<ErrorType = any>() {
  async function postFetchInvoicePdf(id: string): Promise<string> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/invoices/${id}/pdf`
    )
    return response.data.download.download_url
  }

  return useMutation<
    string,
    AxiosError<ErrorType>,
    {
      invoiceId: string
    }
  >(({ invoiceId }) => postFetchInvoicePdf(invoiceId))
}
